/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components

// @mui icons
// Images
import DashboardIcon from "@mui/icons-material/Dashboard";
import AsiTakvimiDashboard from "pages/veteriner/asiTakvimi/AsiTakvimiDashboard";
import AsilarDashboard from "pages/veteriner/asilar/AsilarDashboard";
import AylikListeDashboard from "pages/veteriner/aylikListe/AylikListeDashboard";
import HayvanlarDashboard from "pages/veteriner/hayvanlar/HayvanlarDashboard";
import MusterilerDashboard from "pages/veteriner/musteriler/MusterilerDashboard";
import PasifMusterilerDashboard from "pages/veteriner/pasifMusteriler/PasifMusterilerDashboard";
import SmsDashboard from "pages/veteriner/sms/SmsDashboard";
import Dashboard from "pages/veteriner/dashboard/Dashboard";

const routes = [
  {
    type: "collapse",
    name: "Anasayfa",
    route: "/dashboards/analizler",
    key: "/dashboards/analizler",
    icon: <DashboardIcon />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Aşı Takvimi",
    route: "/asi-takvimi",
    key: "/asi-takvimi",
    icon: <DashboardIcon />,
    component: <AsiTakvimiDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Aylık Liste",
    route: "/aylik-liste",
    key: "/aylik-liste",
    icon: <DashboardIcon />,
    component: <AylikListeDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Müşteriler",
    route: "/musteriler",
    key: "/musteriler",
    icon: <DashboardIcon />,
    component: <MusterilerDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Hayvanlar",
    route: "/hayvanlar",
    key: "/hayvanlar",
    icon: <DashboardIcon />,
    component: <HayvanlarDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Aşılar",
    route: "/asilar",
    key: "/asilar",
    icon: <DashboardIcon />,
    component: <AsilarDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Pasif Müşteriler",
    route: "/pasif-musteriler",
    key: "pasif-musteriler",
    icon: <DashboardIcon />,
    component: <PasifMusterilerDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "SMS",
    route: "/sms",
    key: "sms",
    icon: <DashboardIcon />,
    component: <SmsDashboard />,
    noCollapse: true,
  },

];

export default routes;
