import ConfirmDialog from "components/custom/ConfirmDiaolig";
import AdminLayout from "pages/main/layout/AdminLayout";
import React from "react";
import AsilarData from "./AsilarData";
import AsilarDialog from "./AsilarDialog";

const AsilarDashboard = (props) => {
  return (
    <AdminLayout>
      <AsilarDialog />
      <AsilarData />

      <ConfirmDialog />
    </AdminLayout>
  );
};

AsilarDashboard.propTypes = {};

export default AsilarDashboard;
