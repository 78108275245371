import CalendarData from "./CalendarData";

import AdminLayout from "pages/main/layout/AdminLayout";
const AylikListeDashboard = (props) => {
  return (
    <>
      <AdminLayout>
        <CalendarData />
      </AdminLayout>
    </>
  );
};

AylikListeDashboard.propTypes = {};

export default AylikListeDashboard;
 