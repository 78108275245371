import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import LoadingModal from "components/custom/LoadingModal";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addVaccine,
  closeEditVaccineDialog,
  closeNewVaccineDialog,
  selectvaccines,
  updateVaccine,
} from "store/main/vaccinesSlice";

// import "components/custom/DialogStyle.css";
const defaultFormState = {
  vaccineId: "",
  vaccine: "",
  desc: "",
  period: "",
  // repeat:"",
  familia: "",
};

const familiaOptions = [
  { name: "Kedi", id: "Kedi" },
  { name: "Köpek", id: "Köpek" },
  { name: "Kuş", id: "Kuş" },
  { name: "Papağan", id: "Papağan" },
];

function AsilarDialog(props) {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();
  const owners = useSelector(selectvaccines);
  const vaccineDialog = useSelector(({ vaccines }) => vaccines.vaccineDialog);

  const { form, handleChange, setForm } = useForm(defaultFormState);
  const [familiaId, setFamiliaId] = useState({
    id: 0,
    name: "",
  });

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (vaccineDialog.type === "edit" && vaccineDialog.data) {
      setForm({ ...vaccineDialog.data });
      let famlia = familiaOptions.find(
        (f) => f.id === vaccineDialog.data.familia
      );
      setFamiliaId(famlia);
    }

    /**
     * Dialog type: 'new'
     */
    if (vaccineDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...vaccineDialog.data,
      });
    }
  }, [owners, vaccineDialog.data, vaccineDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (vaccineDialog.props.open) {
      initDialog();
    }
  }, [vaccineDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return vaccineDialog.type === "edit"
      ? dispatch(closeEditVaccineDialog()).then(() => {
          setForm(defaultFormState);
        })
      : dispatch(closeNewVaccineDialog()).then(() => {
          setForm(defaultFormState);
        });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setDataLoading(true);
    if (vaccineDialog.type === "new") {
      let data = {
        ...form,
        familia: familiaId.id, 
      };
      dispatch(addVaccine(data)).then(() => {
        setDataLoading(false);
        setForm(defaultFormState);
        dispatch(closeNewVaccineDialog());
      });
    } else {
      let data = {
        ...form,
        familia: familiaId.id, 
      };
      dispatch(updateVaccine(data)).then(() => {
        setDataLoading(false);
        dispatch(closeEditVaccineDialog());
        setForm(defaultFormState);
      });
    }
    // closeComposeDialog();
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && canBeSubmitted()) {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      closeComposeDialog();
    }
  };

  function canBeSubmitted() {
    if (dataLoading) {
      return false;
    }
    return form.vaccine.length > 0;
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...vaccineDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
      onKeyPress={handleKeyPress}
    >
      <DialogTitle id="company-dialog-title" className="dialog-title">
        Aşı Bilgisi
      </DialogTitle>
      <DialogContent className="dialog-content">
        {dataLoading && <LoadingModal />}

        <TextField
          // autoFocus
          margin="dense"
          id="vaccine"
          name="vaccine"
          label="Aşı"
          type="text"
          fullWidth
          variant="outlined"
          value={form.vaccine}
          onChange={handleChange}
          required
          error={form.vaccine ? false : true}
          helperText="Aşı"
        />
        <TextField
          // // autoFocus
          margin="dense"
          id="desc"
          name="desc"
          label="Açıklama"
          type="text"
          fullWidth
          variant="outlined"
          value={form.desc}
          onChange={handleChange}
        />
        <TextField
          // autoFocus
          margin="dense"
          id="period"
          name="period"
          label="Periot"
          type="number"
          fullWidth
          variant="outlined"
          value={form.period}
          onChange={handleChange}
          helperText="Periot"
        />

        <Autocomplete
          value={familiaId}
          options={familiaOptions}
          getOptionLabel={(option) => option.name}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          noOptionsText="Seçenek yok"
          // fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Familia Seçiniz" />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue.name === "string") {
              setFamiliaId({
                id: newValue.id,
                name: newValue.name,
              });
            } else if (newValue && newValue.inputValue) {
              setFamiliaId({
                name: newValue.inputValue,
              });
            } else {
              setFamiliaId(newValue);
            }
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          İptal
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          // autoFocus
          disabled={!canBeSubmitted()}
        >
          {dataLoading ? "Kaydediliyor..." : "Kaydet"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AsilarDialog;
