import { Box, Card, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingModal from "components/custom/LoadingModal";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditOwnerAnimalDialog,
  selectanimalVaccines,
} from "store/main/animalVaccinesSlice";
import TableContainer from "@mui/material/TableContainer";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, trTR } from "@mui/x-data-grid";

import CustomGridToolbar from "components/custom/CustomGridToolbar";
import MDBox from "components/MDBox";
import { selectanimals } from "store/main/animalsSlice";
import { selectvaccines } from "store/main/vaccinesSlice";
import { closeEditanimalVaccinesDialog } from "store/main/animalVaccinesSlice";
import moment from "moment";
import { selectvaccineCards } from "store/main/vaccineCardsSlice";
function HayvanlarAsiDialog(props) {
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(100);
  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();
  const vaccines = useSelector(selectvaccineCards);
  const animals = useSelector(selectanimals);
  const animalVaccinesDialog = useSelector(
    ({ animalVaccines }) => animalVaccines.animalVaccinesDialog
  );
  const [data, setData] = useState([]);
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const columns = useMemo(
    () => [
      // {
      //   headerName: "ID",
      //   field: "owner",
      //   minWidth: 70,
      //   hideable: false,
      // },
      {
        headerName: "Adı",
        field: "animalName",
        minWidth: 200,
        hideable: false,
      },
      {
        headerName: "Aşı",
        field: "vaccine",
        minWidth: 150,
        hideable: false,
      },
      {
        headerName: "Tarih",
        field: "date",
        minWidth: 180,
        hideable: false,
        type: "date",
        renderCell: (params) => {
          return moment(params.value).format("DD-MM-YYYY");
        },
      },
      {
        headerName: "Durum",
        field: "status",
        minWidth: 200,
        hideable: false,
      },
    ],
    []
  );

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (animalVaccinesDialog.type === "edit" && animalVaccinesDialog.data) {
      console.log("nimalVaccinesDialog.data", animalVaccinesDialog.data);
      const animalsSelected = vaccines
        .filter((f) => f.animalId === animalVaccinesDialog.data.animalId)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      console.log("vaccines", vaccines);
      console.log("animalsSelected", animalsSelected);
      setData(animalsSelected);
    }
  }, [animals, animalVaccinesDialog.data, animalVaccinesDialog.type, setData]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (animalVaccinesDialog.props.open) {
      initDialog();
    }
  }, [animalVaccinesDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return dispatch(closeEditanimalVaccinesDialog()).then(() => {
      setData();
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    closeComposeDialog();
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      closeComposeDialog();
    }
  };

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...animalVaccinesDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
      onKeyPress={handleKeyPress}
    >
      <DialogTitle id="company-dialog-title" className="dialog-title">
        Aşı Bilgisi
      </DialogTitle>
      <DialogContent className="dialog-content">
        {dataLoading && <LoadingModal />}
        <Card>
          <MDBox p={3} lineHeight={1}>
            <TableContainer sx={{ boxShadow: "none" }}>
              <MDBox>
                <Grid container alignItems="center">
                  <Grid container spacing={3} alignItems="right">
                    <Grid item xs={10} md={6} lg={2} mb={4}>
                      <Typography>Aşılar</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ height: 800, width: "100%" }}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          height: 400,
                          width: 1,
                          "& .super-app-theme--1": {
                            bgcolor: (theme) =>
                              getBackgroundColor(
                                theme.palette.error.main,
                                theme.palette.mode
                              ),
                          },
                          "& .super-app-theme--2": {
                            bgcolor: (theme) =>
                              getBackgroundColor(
                                theme.palette.success.main,
                                theme.palette.mode
                              ),
                          },
                        }}
                      >
                        <DataGrid
                          localeText={
                            trTR.components.MuiDataGrid.defaultProps.localeText
                          }
                          rows={data}
                          columns={columns}
                          pageSize={pageSize}
                          rowsPerPageOptions={[20, 50, 100]}
                          pagination
                          autoHeight
                          components={{
                            Toolbar: CustomGridToolbar,
                          }}
                          getRowClassName={(params) =>
                            `super-app-theme--${params.row.status}`
                          }
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          // localeText={tableLocalization}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <div style={{ height: 400, width: "100%" }}></div>
              </MDBox>
            </TableContainer>
          </MDBox>
        </Card>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          İptal
        </Button>
        {/* <Button
          onClick={handleSubmit}
          color="primary"
          // autoFocus
          disabled={!canBeSubmitted()}
        >
          {dataLoading ? "Kaydediliyor..." : "Kaydet"}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default HayvanlarAsiDialog;
