import { Box } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid, trTR } from "@mui/x-data-grid";
import CustomGridToolbar from "components/custom/CustomGridToolbar";
import React, { useEffect, useMemo, useState } from "react";
const CustomerList = ({ data }) => {
  const [pageSize, setPageSize] = useState(100);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(data);
  }, [data, tableData, setTableData]);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Müşteri Adı", width: 150 },
      { field: "phone", headerName: "Telefonu", width: 130 },
    ],
    []
  );

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  return (
    <>
      <div style={{ height: 800, width: "100%" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: 400,
                width: 1,
                "& .super-app-theme--pasif": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.error.main,
                      theme.palette.mode
                    ),
                },
                "& .super-app-theme--tamamlandi": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.info.main,
                      theme.palette.mode
                    ),
                },
              }}
            >
              <DataGrid
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                rows={tableData}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[20, 50, 100]}
                pagination
                autoHeight
                components={{
                  Toolbar: CustomGridToolbar,
                }}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.status}`
                }
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // localeText={tableLocalization}
              />
            </Box>
          </div>
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}></div>
    </>
  );
};

export default CustomerList;
