import React from 'react'
import PropTypes from 'prop-types'

const PasifMusterilerDashboard = props => {
  return (
    <div>PasifMusterilerDashboard</div>
  )
}

PasifMusterilerDashboard.propTypes = {}

export default PasifMusterilerDashboard