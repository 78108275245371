import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmDialog } from "store/common/confirmslice";
import { removeAnimal } from "store/main/animalsSlice";
import { removeOwner } from "store/main/ownersSlice";
import { removeVaccine } from "store/main/vaccinesSlice";
import "./DialogStyle.css";
import LoadingModal from "./LoadingModal";
const defaultFormState = {
  id: null,
};
function ConfirmDialog(props) {
  const dispatch = useDispatch();
  const confirmDialog = useSelector(
    ({ confirmDialog }) => confirmDialog.confirmDialog
  );
  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const initDialog = useCallback(() => {
    setForm({
      ...defaultFormState,
      ...confirmDialog.data,
    });
  }, [confirmDialog.data, confirmDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (confirmDialog.props.open) {
      initDialog();
    }
  }, [confirmDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return dispatch(closeConfirmDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (form && form.dialogType) {
      setDataLoading(true);
      switch (form.dialogType) {
        case "animal":
          console.log("form", form);
          dispatch(removeAnimal(form.id)).then(() => {
            setDataLoading(false);
            return dispatch(closeConfirmDialog());
          });
          break;
        case "owner":
          console.log("form", form);
          dispatch(removeOwner(form.id)).then(() => {
            setDataLoading(false);
            return dispatch(closeConfirmDialog());
          });
          break;
        case "vaccine":
          console.log("form", form);
          dispatch(removeVaccine(form.id)).then(() => {
            setDataLoading(false);
            return dispatch(closeConfirmDialog());
          });
          break;
        default:
          break;
      }
    }
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...confirmDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="company-dialog-title"
        className="confirm-dialog-title"
        style={{
          color: "#a83434",
          fontWeight: "bold",
        }}
      >
        Silme Onayı
      </DialogTitle>
      <DialogContent
        className="confirm-dialog-content"
        style={{
          color: "#4285F4",
        }}
      >
        {dataLoading && <LoadingModal />}
        <p>Silmek istediğinizden emin misiniz ?</p>
        <p
          className="note"
          style={{
            fontSize: "0.9rem",
            fontStyle: "italic",
            color: "#999999",
            marginTop: "10px",
          }}
        >
          Silersiniz ilişkili tüm veriler silinecektir.
        </p>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={closeComposeDialog}
          className="confirm-dialog-button-cancel"
          style={{
            backgroundColor: "#1967D2",
            color: "#ffffff",
          }}
        >
          İptal
        </Button>
        <Button
          onClick={handleSubmit}
          className="confirm-dialog-button-confirm"
          autoFocus
          style={{
            backgroundColor: "#a83434",
            color: "#ffffff",
          }}
        >
          {dataLoading ? "Siliniyor..." : "Sil"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
