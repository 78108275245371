import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import auth from "./auth/authSlice";
import confirmDialog from "./common/confirmslice";
import animals from "./main/animalsSlice";
import owners from "./main/ownersSlice";
import users from "./main/usersSlice";
import vaccineCards from "./main/vaccineCardsSlice";
import vaccines from "./main/vaccinesSlice";
import ownerAnimals from "./main/ownerAnimalsSlice";
import animalVaccines from "./main/animalVaccinesSlice";
const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    toastr: toastrReducer,
    confirmDialog,
    vaccines,
    owners,
    animals,
    users,
    vaccineCards,
    ownerAnimals,
    animalVaccines,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
