import PharmacyIcon from "@mui/icons-material/Login";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "features/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "store/auth/authSlice";
// import backgroundImage from "assets/images/pharmacy-bg.jpg";
// import backgroundImage from "assets/images/illustrations/sign-in-1.jpg";
import backgroundImage from "assets/images/illustrations/sign-in-bg.jpg";
const defaultFormState = {
  userName: "",
  password: "",
};

function SignIn() {
  const navigate = useNavigate();
  const { form, handleChange } = useForm(defaultFormState);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboards/analizler");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(login(form)).then((params) => {
      setTimeout(() => setLoading(false), 1000);
      if (params.payload.isAuthenticated) {
        navigate("/dashboards/analizler");
      }
    });
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          maxWidth: 400,
          padding: "2rem",
          borderRadius: 10,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          background: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <PharmacyIcon
            style={{
              height: "60px", // Increase height to 60px
              width: "60px", // Increase width to 60px
              marginBottom: "0.5rem",
              color: "#1976d2",
            }}
          />
          <Typography variant="h5" component="h1">
            Giriş Yap
          </Typography>
        </div>
        <form onSubmit={handleLogin}>
          <TextField
            type="text"
            label="Kullanıcı Adı"
            fullWidth
            value={form.userName}
            onChange={handleChange}
            name="userName"
            margin="normal"
            variant="outlined"
          />
          <TextField
            type="password"
            label="Şifre"
            fullWidth
            value={form.password}
            onChange={handleChange}
            name="password"
            margin="normal"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            disabled={loading}
            startIcon={<LockIcon />}
            style={{ marginTop: "1.5rem", color: "white" }}
          >
            {loading ? "Giriş yapılıyor..." : "Giriş Yap"}
          </Button>
        </form>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Switch
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <Typography variant="body2" style={{ marginLeft: "0.5rem" }}>
            Remember me
          </Typography>
        </div> */}
        {/* <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Typography variant="body2" style={{ color: "#1976d2" }}>
              Şifremi Unuttum?
            </Typography>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default SignIn;
