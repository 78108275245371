import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import React from "react";
const AnimalCard = ({ selectedAnimalData }) => {
  return (
    <>
      <MDBox display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Sahibi : &nbsp; 
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{selectedAnimalData?.ownerName}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          D.Günü : &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{moment(selectedAnimalData?.birth).format("DD-MM-YYYY")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Cinsiyet : &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{selectedAnimalData?.gender}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Türü : &nbsp; 
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{selectedAnimalData?.familia}
        </MDTypography>
      </MDBox>
      <MDBox key={selectedAnimalData.id} display="flex" py={1} pr={2}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Kilo : &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{selectedAnimalData?.weight}
        </MDTypography>
      </MDBox>
    </>
  );
};

AnimalCard.propTypes = {};

export default AnimalCard;
