import {
  createSlice
} from "@reduxjs/toolkit";

const confirmDialogSlice = createSlice({
  name: "confirmDialog",
  initialState: {
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    noPackageDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    }, 
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
    openNoPackageDialog: (state, action) => {
      state.noPackageDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeNoPackageDialog: (state, action) => {
      state.noPackageDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
});

export const {
  openConfirmDialog,
  closeConfirmDialog,
  openNoPackageDialog,
  closeNoPackageDialog,
} = confirmDialogSlice.actions;

export default confirmDialogSlice.reducer;
