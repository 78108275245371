import axios from "axios";
import moment from "moment";
import { setAlert } from "../actions/alert";

// const key = "ca85229f9b6b04bb1272666e19748aec";
// const hash = "126d4a0b8cfdff1f89c1abbfa1ddedb1cc4566498954b9f25c2cde2151def8fb";
const key = "aa855ab225cf0fd9b3f5c158fcda8c0b";
const hash = "d380142f7e245f2af438f70e854d88eb7104ebf54b09ef1fe586dfd6e6f8d8c2";

export const sendSms = (recepients, format, smsContent) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (format === "custom") {
        let recepientsPhone = recepients.map((r) => r.phone);
        let sezaiPhone = "5453286527";
        recepientsPhone.push(sezaiPhone);
        const smsObj = {
          request: {
            authentication: {
              key: key,
              hash: hash,
            },
            order: {
              sender: "SUVETERINER",
              sendDateTime: [],
              message: {
                text: smsContent,
                receipents: {
                  number: recepientsPhone,
                },
              },
            },
          },
        };
        const res = await axios.post(
          `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
          smsObj,
          config
        );

        const response = res?.data?.smsReponse;
        const status = response?.status;
        dispatch(setAlert(status?.message, "white"));
      }
      if (format === "standart") {
        let status = {};
        recepients.map(async (recepient) => {
          let type = "";
          let vaccineText = "";
          if (
            recepient.familia === "KÖPEK" ||
            recepient.familia === "Köpek" ||
            recepient.familia === "köpek"
          ) {
            type = "Köpeğiniz";
          } else if (
            recepient.familia === "Kedi" ||
            recepient.familia === "Kedi" ||
            recepient.familia === "kedi"
          ) {
            type = "Kediniz";
          }
          if (recepient.vaccine.toLowerCase().includes("parazit")) {
            vaccineText = "uygulaması vardır.";
          } else {
            vaccineText = "aşı uygulaması vardır.";
          }

          const content = `${type} ${recepient.name} için ${moment(
            recepient.date
          ).format("DD.MM.YYYY")} de ${
            recepient.vaccine
          } ${vaccineText} Kliniğimize bekler sağlıklı günler dileriz.`;

          const smsObj = {
            request: {
              authentication: {
                key: key,
                hash: hash,
              },
              order: {
                sender: "SUVETERINER",
                sendDateTime: [],
                message: {
                  text: content,
                  receipents: {
                    number: recepient.phone,
                  },
                },
              },
            },
          };

          const res = await axios.post(
            `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
            smsObj,
            config
          );

          // const response = res?.data?.response;
          // status = response?.status;
        });

        const smsObjSezai = {
          request: {
            authentication: {
              key: key,
              hash: hash,
            },
            order: {
              sender: "SUVETERINER",
              sendDateTime: [],
              message: {
                text:
                  "Günlük aşı hatırlatma smsi ilgili müşterilere gönderildi",
                receipents: {
                  number: "5453286527",
                },
              },
            },
          },
        };
        const res = await axios.post(
          `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
          smsObjSezai,
          config
        );

        dispatch(setAlert(status?.message, "white"));
      }

      // const username = "5347926583";
      // const password = "2020bizimM";
      // const sender = "ARPETA"
      // const res = await axios.get(`v1/send-sms/get/`, {
      //   params: {
      //     username: username,
      //     password: password,
      //     text: "deneme2",
      //     receipents: "5347926583",
      //     sender: sender,
      //   },
      // });
    } catch (error) {
      console.log("error", error);
      dispatch(setAlert("Bir hata oluştu tekrar deneyiniz!", "danger"));
      console.log("error", error);
    }
  };
};

export const getDebit = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      const res = await axios.get(
        "https://us-central1-vetapp-fbd6b.cloudfunctions.net/getsmsdebit"
      );
      const response = res?.data?.balance?.response;
      const balance = response?.balance;
      const sms = balance?.sms;
      return sms;
    } catch (error) {}
  };
};

export const sendSmsV2 = (recepients, format, smsContent) => {
  return async (dispatch, getState,) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (format === "custom") {
        let recepientsPhone = recepients.map((r) => r.phone);
        let sezaiPhone = "5453286527";
        recepientsPhone.push(sezaiPhone);
        const smsObj = {
          request: {
            authentication: {
              key: key,
              hash: hash,
            },
            order: {
              sender: "SUVETERINER",
              sendDateTime: [],
              message: {
                text: smsContent,
                receipents: {
                  number: recepientsPhone,
                },
              },
            },
          },
        };
        const res = await axios.post(
          `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
          smsObj,
          config
        );
        const response = res?.data?.smsReponse;
        const status = response?.status;
        dispatch(setAlert(status?.message, "white"));
      }
      if (format === "standart") {
        let status = {};
        recepients.map(async (recepient) => {
          let type = "";
          let vaccineText = "";
          if (
            recepient.familia === "KÖPEK" ||
            recepient.familia === "Köpek" ||
            recepient.familia === "köpek"
          ) {
            type = "Köpeğiniz";
          } else if (
            recepient.familia === "Kedi" ||
            recepient.familia === "Kedi" ||
            recepient.familia === "kedi"
          ) {
            type = "Kediniz";
          }
          if (recepient.vaccine.toLowerCase().includes("parazit")) {
            vaccineText = "uygulaması vardır.";
          } else {
            vaccineText = "aşı uygulaması vardır.";
          }

          const content = `${type} ${recepient.name} için ${moment(
            recepient.date
          ).format("DD.MM.YYYY")} de ${
            recepient.vaccine
          } ${vaccineText} Kliniğimize bekler sağlıklı günler dileriz.`;

          const smsObj = {
            request: {
              authentication: {
                key: key,
                hash: hash,
              },
              order: {
                sender: "SUVETERINER",
                sendDateTime: [],
                message: {
                  text: content,
                  receipents: {
                    number: recepient.phone,
                  },
                },
              },
            },
          };

          const res = await axios.post(
            `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
            smsObj,
            config
          );

          // const response = res?.data?.response;
          // status = response?.status;
        });

        const smsObjSezai = {
          request: {
            authentication: {
              key: key,
              hash: hash,
            },
            order: {
              sender: "SUVETERINER",
              sendDateTime: [],
              message: {
                text:
                  "Günlük aşı hatırlatma smsi ilgili müşterilere gönderildi",
                receipents: {
                  number: "5453286527",
                },
              },
            },
          },
        };
        const res = await axios.post(
          `https://us-central1-vetapp-fbd6b.cloudfunctions.net/sendsms`,
          smsObjSezai,
          config
        );

        dispatch(setAlert(status?.message, "white"));
      }

    } catch (error) {
      console.log("error", error);
      dispatch(setAlert("Bir hata oluştu tekrar deneyiniz!", "danger"));
      console.log("error", error);
    }
  };
};

export const getDebitV2 = () => {
  return async (dispatch, getState) => {
    try {
      const res = await axios.get(
        "https://us-central1-vetapp-fbd6b.cloudfunctions.net/getsmsdebit"
      );
      const response = res?.data?.balance?.response;
      const balance = response?.balance;
      const sms = balance?.sms;
      return sms;
    } catch (error) {}
  };
};
