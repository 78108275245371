import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import axiosConfig from "../../features/api/axiosConfig";

export const getVaccineCards = createAsyncThunk(
  "vaccineCards/getVaccineCards",
  async () => {
    const response = await axiosConfig.get(`/api/vaccineCards`);
    // const response = await axios.get(`${proxy}/api/vaccineCards`)

    let { data } = await response.data;
    return data;
  }
);

export const addVaccineCard = createAsyncThunk(
  "vaccineCards/addVaccineCard",
  async (vaccineCard, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/vaccineCards`, vaccineCard);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        console.log("data addVaccineCard" , data);
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().vaccineCards);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().vaccineCards);
    }
  }
);

export const addVaccineCards = createAsyncThunk(
  "vaccineCards/addVaccineCards",
  async (vaccineCards, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/vaccineCards`,
        vaccineCards
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().vaccineCards);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().vaccineCards);
    }
  }
);

export const updateVaccineCard = createAsyncThunk(
  "vaccineCards/updateVaccineCard",
  async (vaccineCard, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/vaccineCards/${vaccineCard.cardId}`,
      vaccineCard
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().vaccineCards);
    }
  }
);

export const removeVaccineCard = createAsyncThunk(
  "vaccineCards/removeVaccineCard",
  async (cardId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/vaccineCards/${cardId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return cardId;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().vaccineCards);
    }
  }
);

const vaccineCardsAdapter = createEntityAdapter({
  selectId: (vaccineCard) => vaccineCard.cardId,
});

export const {
  selectAll: selectvaccineCards,
  selectById: selectvaccineCardsById,
} = vaccineCardsAdapter.getSelectors((state) => state.vaccineCards);

const vaccineCardsSlice = createSlice({
  name: "vaccineCards",
  initialState: vaccineCardsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    vaccineCardDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setVaccineCardSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewVaccineCardDialog: (state, action) => {
      state.vaccineCardDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewVaccineCardDialog: (state, action) => {
      state.vaccineCardDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditVaccineCardDialog: (state, action) => {
      state.vaccineCardDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditVaccineCardDialog: (state, action) => {
      state.vaccineCardDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateVaccineCard.fulfilled]: vaccineCardsAdapter.upsertOne,
    [addVaccineCards.fulfilled]: vaccineCardsAdapter.upsertMany,
    [addVaccineCard.fulfilled]: vaccineCardsAdapter.upsertOne,
    [removeVaccineCard.fulfilled]: (state, action) =>
      vaccineCardsAdapter.removeOne(state, action.payload),
    [getVaccineCards.fulfilled]: vaccineCardsAdapter.setAll,
  },
});

export const {
  setVaccineCardSearchText,
  openNewVaccineCardDialog,
  closeNewVaccineCardDialog,
  openEditVaccineCardDialog,
  closeEditVaccineCardDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = vaccineCardsSlice.actions;

export default vaccineCardsSlice.reducer;
