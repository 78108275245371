import React from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import HealingIcon from '@mui/icons-material/Healing';

const BasicInfo = ({ animalCount, customerCount, vaccineCount }) => {
  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Genel Bilgiler
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader avatar={<PersonIcon />} title="Müşteri Sayısı" subheader="Toplam Müşteri Sayınız" />
            <CardContent>
              <Typography variant="h5" component="p" className="u-text-primary-color-big">
                {customerCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader avatar={<PetsIcon />} title="Hayvan Sayısı" subheader="Toplam Hayvan Sayınız" />
            <CardContent>
              <Typography variant="h5" component="p" className="u-text-primary-color-big">
                {animalCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader avatar={<HealingIcon />} title="Aşı Sayısı" subheader="Mevcut Tanımlı Aşı Sayısı" />
            <CardContent>
              <Typography variant="h5" component="p" className="u-text-primary-color-big">
                {vaccineCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicInfo;
