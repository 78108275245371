/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// RTL plugins
// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
// Images
import {
  default as brandDark,
  default as brandWhite,
} from "assets/images/arpetaLogo.png";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import "components/custom/DialogStyle.css";
import PharmacyLoader from "components/custom/Loading/PharmacyLoader";
// Material Dashboard 2 PRO React routes
// Material Dashboard 2 PRO React contexts
import { setMiniSidenav, useMaterialUIController } from "context";
import setAuthToken from "features/api/setAuthToken";
import Sidenav from "pages/main/Sidenav";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import adminRoutes from "routes/adminRoutes";
import webPageRoutes from "routes/webPageRoutes";
import { loadUser } from "store/auth/authSlice";

import "mapbox-gl/dist/mapbox-gl.css";
import { getAnimals } from "store/main/animalsSlice";
import { getOwners } from "store/main/ownersSlice";
import { getVaccineCards } from "store/main/vaccineCardsSlice";
import { getVaccines } from "store/main/vaccinesSlice";
import "./App.css";
import { logout } from "store/auth/authSlice";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  const dispatchEvent = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userAuth = useSelector((state) => state.auth.userAuth);
  const userName = useSelector((state) => state.auth.userName);
  const [whereToGo, setWhereToGo] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoading2, setDataLoading2] = useState(false);
  const [dataLoading3, setDataLoading3] = useState(false);
  const [dataLoading4, setDataLoading4] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    if (localStorage.token) {
      dispatchEvent(loadUser()).then(() => {
        setTimeout(() => setLoading(false), 1000);
      });
    } else {
      setLoading(false);
    }

  }, []);

  useEffect(() => {
    switch (userAuth) {
      case "admin":
        setDataLoading(true);
        setDataLoading2(true);
        setDataLoading4(true);
        setDataLoading3(true);
        dispatchEvent(getOwners()).then(() => setDataLoading(false));
        dispatchEvent(getAnimals()).then(() => setDataLoading2(false));
        dispatchEvent(getVaccines()).then(() => setDataLoading3(false));
        dispatchEvent(getVaccineCards()).then(() => setDataLoading4(false));
        break;

      default:
        break;
    }
  }, [userAuth]);

  const getRoutes = (allRoutes) =>
    allRoutes &&
    allRoutes.length > 0 &&
    Array.isArray(allRoutes) &&
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  if (loading) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <PharmacyLoader />;
      </ThemeProvider>
    );
  }
  if (dataLoading || dataLoading2 || dataLoading3 || dataLoading4) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <PharmacyLoader />;
      </ThemeProvider>
    );
  }

  if (isAuthenticated === false && loading === false) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />

        <Routes>
          {getRoutes(webPageRoutes)}
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </ThemeProvider>
    );
  }
  if (userAuth === "admin")
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Su Veteriner"
              routes={adminRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}

        <Routes>{getRoutes(adminRoutes)}</Routes>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <PharmacyLoader />;
    </ThemeProvider>
  );
};

export default App;
