import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TableContainer from "@mui/material/TableContainer";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, trTR } from "@mui/x-data-grid";
import theme from "assets/theme";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomGridToolbar from "components/custom/CustomGridToolbar";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openConfirmDialog } from "store/common/confirmslice";
import {
  openEditVaccineDialog,
  openNewVaccineDialog,
  selectvaccines,
} from "store/main/vaccinesSlice";

const AsilarData = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectvaccines);

  const [pageSize, setPageSize] = useState(100);

  const handleOpenNew = () => {
    dispatch(openNewVaccineDialog());
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const editData = React.useCallback(
    (id) => () => {
      let data = id.row;
      dispatch(openEditVaccineDialog(data));
    },
    []
  );
  const deleteData = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        dialogType: "animal",
      };
      dispatch(openConfirmDialog(data));
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        width: 170,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{ color: theme.palette.success.main }} />} // Use theme color for error
            label="Düzenle"
            onClick={editData(params)}
            disabled={params.row.status === "pasif" ? true : false}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: theme.palette.error.main }} />} // Use theme color for error
            label={"Sil"}
            onClick={deleteData(params)}
            showInMenu
          />,
        ],
      },
      // {
      //   headerName: "ID",
      //   field: "owner",
      //   minWidth: 70,
      //   hideable: false,
      // },
      {
        headerName: "Aşı",
        field: "vaccine",
        minWidth: 200,
        hideable: false,
      },
      {
        headerName: "Açıklama",
        field: "desc",
        minWidth: 200,
        hideable: false,
      },
      {
        headerName: "Filya",
        field: "familia",
        minWidth: 150,
        hideable: false,
      },
      {
        headerName: "Periyot",
        field: "period",
        minWidth: 150,
        hideable: false,
      },
      {
        headerName: "Familia",
        field: "familia",
        minWidth: 200,
        hideable: false,
      },
    ],
    [editData, deleteData]
  );

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid container spacing={3} alignItems="right">
                <Grid item xs={10} md={6} lg={2} mb={4}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleOpenNew}
                  >
                    <Icon>add</Icon>&nbsp;Yeni Hayvan ekle
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ height: 800, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: 400,
                      width: 1,
                      "& .super-app-theme--pasif": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        trTR.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[20, 50, 100]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: CustomGridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      // localeText={tableLocalization}
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div style={{ height: 400, width: "100%" }}></div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

export default AsilarData;
