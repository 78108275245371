import React from 'react';
import './PharmacyLoader.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPrescriptionBottleAlt } from "@fortawesome/free-solid-svg-icons";
const PharmacyLoader = () => {
  return (
    <div className="pharmacy-modal">
      <div className="pharmacy">
        <div className="pharmacy-apple"> 
          <FontAwesomeIcon icon={faPrescriptionBottleAlt} size="4x" color="black"/>
        </div>
      </div>
    </div>
  );
}

export default PharmacyLoader;
