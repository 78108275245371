import { Box } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid, trTR } from "@mui/x-data-grid";
import CustomGridToolbar from "components/custom/CustomGridToolbar";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
const CardList = ({ data, animals, customers }) => {
  const [pageSize, setPageSize] = useState(100);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const rows = data?.map((d) => {
      let animal = animals?.find((a) => a.id === d.animalId);
      let customer = customers?.find((c) => c.id === animal?.owner);
      return {
        id: d.id,
        animalName: animal?.name,
        animalType: animal?.familia,
        customerName: customer?.name,
        customerPhone: customer?.phone,
        vaccination: moment(d?.date).format("DD-MM-YYYY"),
        date: moment(d?.date).format("DD-MM-YYYY"),
      };
    });

    setTableData(rows);
  }, [data, animals, customers, tableData, setTableData]);

  const columns = useMemo(
    () => [
      { field: "animalName", headerName: "Hayvan Adı", width: 150 },
      { field: "animalType", headerName: "Türü", width: 130 },
      { field: "customerName", headerName: "Müşteri Adı", width: 150 },
      { field: "customerPhone", headerName: "Telefonu", width: 130 },
      { field: "vaccination", headerName: "Aşı", width: 130 },
      { field: "date", headerName: "Tarih", width: 130 },
    ],
    []
  );

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  return (
    <>
    <div style={{ height: 800, width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <Box
            sx={{
              height: 400,
              width: 1,
              "& .super-app-theme--pasif": {
                bgcolor: (theme) =>
                  getBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode
                  ),
              },
              "& .super-app-theme--tamamlandi": {
                bgcolor: (theme) =>
                  getBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                  ),
              },
            }}
          >
            <DataGrid
              localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
              rows={tableData}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              autoHeight
              components={{
                Toolbar: CustomGridToolbar,
              }}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.status}`
              }
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // localeText={tableLocalization}
            />
          </Box>
        </div>
      </div>
    </div>
       <div style={{ height: 400, width: "100%" }}></div>
       </>
         
  );
};

export default CardList;
