import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import moment from "moment";
import AdminLayout from "pages/main/layout/AdminLayout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
// import { setAlert } from "../../actions/alert";
import { selectanimals } from "store/main/animalsSlice";
import { selectowners } from "store/main/ownersSlice";
import { selectvaccineCards } from "store/main/vaccineCardsSlice";
import { selectvaccines } from "store/main/vaccinesSlice";
import { getDebitV2, sendSmsV2 } from "../../../actions/smsSend";
import CardList from "./CardList";
import CustomerList from "./CustomerList";

const SmsDashboard = (props) => {
  const dispatch = useDispatch();
  const [peoples, setPeoples] = useState("today");
  const [format, setFormat] = useState("standart");
  const [smsContent, setSmsContent] = useState("");
  const [smsRecepients, setSmsRecepients] = useState("");
  const [smsDebit, setSmsDebit] = useState(0);

  let todayDate = moment().format("YYYY-MM-DD");
  let tomorrowDate = moment().add(1, "days").format("YYYY-MM-DD");
  let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  const animals = useSelector(selectanimals);
  const customers = useSelector(selectowners);
  const vaccines = useSelector(selectvaccines);
  const vaccineCards = useSelector(selectvaccineCards);
  const [passiveCustomers, setpassiveCustomers] = useState([]);
  const [todayAppointments, settodayAppointments] = useState([]);
  const [tomorrowAppointments, settomorrowAppointments] = useState([]);
  const [yesterdayAppointments, setyesterdayAppointments] = useState([]);

  useEffect(() => {
    const todayFiltered = vaccineCards.filter((f) => f.date === todayDate);
    const tomorrowFiltered = vaccineCards.filter(
      (f) => f.date === tomorrowDate
    );
    const yesterdayFiltered = vaccineCards.filter((f) => f.date === yesterday);
    console.log("yesterdat", yesterday);
    console.log("yesterdayFiltered", yesterdayFiltered);
    settodayAppointments(todayFiltered);
    settomorrowAppointments(tomorrowFiltered);
    setyesterdayAppointments(yesterdayFiltered);
  }, [vaccineCards]);
  useEffect(() => {
    const filteredCustomers = customers.filter((f) => f.status === 2);
    setpassiveCustomers(filteredCustomers);
  }, [customers]);

  useEffect(() => {
    dispatch(getDebitV2()).then((debit) => setSmsDebit(debit));
  }, []);
  useEffect(() => {
    let content = "";
    if (
      (peoples === "today" ||
        peoples === "tomorrow" ||
        peoples === "yesterday") &&
      format === "standart"
    ) {
      content = "Bugün .... isimli ....nizin. ..... Aşı günüdür..";
    }
    if (peoples === "customers") {
      setFormat("custom");
      setSmsRecepients(customers);
    }
    if (peoples === "passive") {
      setFormat("custom");
      setSmsRecepients(passiveCustomers);
    }
    if (peoples === "today") {
      let customerData = [];
      todayAppointments &&
        todayAppointments.length > 0 &&
        todayAppointments.map((data) => {
          console.log("data", data);
          let row = {};
          let animal =
            animals &&
            animals.length > 0 &&
            animals.find((animal) => animal.id === data.animalId);
          console.log("animal", animal);
          let customer =
            customers &&
            customers.length > 0 &&
            customers.find((cust) => cust.id === animal?.owner);
          console.log("animal", animal);

          if (customer && customer.name) {
            row = { ...customer, ...animal, ...data };
            customerData.push(row);
          }
        });
      if (customerData && customerData.length > 0) {
        setSmsRecepients(customerData);
      } else {
        setSmsRecepients();
      }
    }

    if (peoples === "tomorrow") {
      let customerData = [];
      tomorrowAppointments &&
        tomorrowAppointments.length > 0 &&
        tomorrowAppointments.map((data) => {
          let row = {};
          let animal =
            animals &&
            animals.length > 0 &&
            animals.find((animal) => animal.id === data.animalId);
          let customer =
            customers &&
            customers.length > 0 &&
            customers.find((cust) => cust.id === animal?.owner);

          if (customer && customer.name) {
            row = { ...customer, ...animal, ...data };
            customerData.push(row);
          }
        });
      if (customerData && customerData.length > 0) {
        setSmsRecepients(customerData);
      } else {
        setSmsRecepients();
      }
    }

    if (peoples === "yesterday") {
      let customerData = [];
      console.log("yesterdayAppointments", yesterdayAppointments);
      yesterdayAppointments &&
        yesterdayAppointments.length > 0 &&
        yesterdayAppointments.map((data) => {
          let row = {};
          let animal =
            animals &&
            animals.length > 0 &&
            animals.find((animal) => animal.id === data.animalId);
          let customer =
            customers &&
            customers.length > 0 &&
            customers.find((cust) => cust.id === animal?.owner);

          if (customer && customer.name) {
            row = { ...customer, ...animal, ...data };
            customerData.push(row);
          }
        });
      if (customerData && customerData.length > 0) {
        setSmsRecepients(customerData);
      } else {
        setSmsRecepients();
      }
    }
    setSmsContent(content);
  }, [peoples, format]);
  const handleChange = (event) => {
    setPeoples(event.target.value);
  };

  const handleChangeFormat = (event) => {
    setFormat(event.target.value);
  };
  const handleChangeContent = (event) => {
    setSmsContent(event.target.value);
  };

  const handleSendSms = async (e) => {
    e.preventDefault();
    if (smsRecepients && smsRecepients.length > 0 && format && smsContent) {
      dispatch(sendSmsV2(smsRecepients, format, smsContent));
      const debit = await dispatch(getDebitV2());

      setSmsDebit(debit);
      setSmsContent("");
    } else {
      toastr.error(
        "Hata",
        "Sms gönderilemez.Lütfen alıcıları veya sms içeriğini kontrol ediniz!"
      );
    }
  };

  let buttonDisabled = true;
  if (
    peoples === "customers" &&
    customers.length > 0 &&
    smsContent.length > 0 &&
    smsRecepients &&
    smsRecepients.length > 0
  ) {
    buttonDisabled = false;
  } else {
    buttonDisabled = true;
  }
  if (
    peoples === "today" &&
    todayAppointments.length > 0 &&
    smsContent.length > 0 &&
    smsRecepients &&
    smsRecepients.length > 0
  ) {
    buttonDisabled = false;
  } else if (
    peoples === "tomorrow" &&
    tomorrowAppointments.length > 0 &&
    smsContent.length > 0 &&
    smsRecepients &&
    smsRecepients.length > 0
  ) {
    buttonDisabled = false;
  } else if (
    peoples === "yesterday" &&
    yesterdayAppointments.length > 0 &&
    smsContent.length > 0 &&
    smsRecepients &&
    smsRecepients.length > 0
  ) {
    buttonDisabled = false;
  } else if (
    peoples === "customers" &&
    smsContent.length > 0 &&
    customers &&
    customers.length > 0
  ) {
    buttonDisabled = false;
  } else if (
    peoples === "passive" &&
    smsContent.length > 0 &&
    passiveCustomers &&
    passiveCustomers.length > 0
  ) {
    buttonDisabled = false;
  } else {
    buttonDisabled = true;
  }

  return (
    <AdminLayout>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography variant="h6">SMS Bakiyesi: {smsDebit}</Typography>
        <Typography variant="h6">SMS Gönder</Typography>

        <FormControl component="fieldset">
          <FormLabel component="legend">SMS Gönderilcek Kişiler</FormLabel>
          <RadioGroup
            name="radioGroupPeople"
            value={peoples}
            onChange={handleChange}
          >
            <FormControlLabel
              value="today"
              control={<Radio />}
              label="Bugün Randevusu olanlar"
            />
            <FormControlLabel
              value="tomorrow"
              control={<Radio />}
              label="Yarın Randevusu olanlar"
            />
            <FormControlLabel
              value="yesterday"
              control={<Radio />}
              label="Dün Randevusu olanlar"
            />
            <FormControlLabel
              value="customers"
              control={<Radio />}
              label="Aktif Müşteriler"
            />
            <FormControlLabel
              value="passive"
              control={<Radio />}
              label="Pasif Müşteriler"
            />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ my: 2 }} />

        <FormControl component="fieldset">
          <FormLabel component="legend">SMS Formatı</FormLabel>
          <RadioGroup
            name="radioGroupFormat"
            value={format}
            onChange={handleChangeFormat}
          >
            <FormControlLabel
              value="standart"
              control={<Radio />}
              label="Standart Format"
            />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Özel Format"
            />
          </RadioGroup>
        </FormControl>

        <Divider sx={{ my: 2 }} />

        <FormControl fullWidth>
          <FormLabel>SMS İçeriği</FormLabel>
          <TextareaAutosize
            minRows={3}
            placeholder="SMS İçeriği"
            value={smsContent}
            onChange={handleChangeContent}
            style={{ width: "100%" }}
            disabled={format === "standart"}
          />
        </FormControl>

        <Button
          variant="contained"
          onClick={handleSendSms}
          disabled={buttonDisabled}
          sx={{ mt: 2 }}
        >
          SMS Gönder
        </Button>
        {/* Conditionally rendered lists */}
        {peoples === "customers" && <CustomerList data={customers} />}
        {peoples === "passive" && <CustomerList data={passiveCustomers} />}
        {peoples === "today" &&
          todayAppointments &&
          todayAppointments.length > 0 && (
            <CardList
              data={todayAppointments}
              customers={customers}
              animals={animals}
            />
          )}
        {peoples === "tomorrow" &&
          tomorrowAppointments &&
          tomorrowAppointments.length > 0 && (
            <CardList
              data={tomorrowAppointments}
              customers={customers}
              animals={animals}
            />
          )}
        {peoples === "yesterday" &&
          yesterdayAppointments &&
          yesterdayAppointments.length > 0 && (
            <CardList
              data={yesterdayAppointments}
              customers={customers}
              animals={animals}
            />
          )}
      </Box>
    </AdminLayout>
  );
};

export default SmsDashboard;
