import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import axiosConfig from "../../features/api/axiosConfig";

export const getVaccines = createAsyncThunk(
  "vaccines/getVaccines",
  async () => {
    const response = await axiosConfig.get(`/api/vaccines`);
    // const response = await axios.get(`${proxy}/api/vaccines`)

    let { data } = await response.data;
    return data;
  }
);

export const addVaccine = createAsyncThunk(
  "vaccines/addVaccine",
  async (vaccine, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/vaccines`, vaccine);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().vaccines);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().vaccines);
    }
  }
);
export const updateVaccine = createAsyncThunk(
  "vaccines/updateVaccine",
  async (vaccine, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/vaccines/${vaccine.vaccineId}`,
      vaccine
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().vaccines);
    }
    return null;
  }
);

export const removeVaccine = createAsyncThunk(
  "vaccines/removeVaccine",
  async (vaccineId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/vaccines/${vaccineId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return vaccineId;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().vaccines);
    }
  }
);

const vaccinesAdapter = createEntityAdapter({
  selectId: (vaccine) => vaccine.vaccineId,
});

export const { selectAll: selectvaccines, selectById: selectvaccinesById } =
  vaccinesAdapter.getSelectors((state) => state.vaccines);

const vaccinesSlice = createSlice({
  name: "vaccines",
  initialState: vaccinesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    vaccineDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setVaccineSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewVaccineDialog: (state, action) => {
      state.vaccineDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewVaccineDialog: (state, action) => {
      state.vaccineDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditVaccineDialog: (state, action) => {
      state.vaccineDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditVaccineDialog: (state, action) => {
      state.vaccineDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateVaccine.fulfilled]: vaccinesAdapter.upsertOne,
    [addVaccine.fulfilled]: vaccinesAdapter.addOne,
    [removeVaccine.fulfilled]: (state, action) =>
      vaccinesAdapter.removeOne(state, action.payload),
    [getVaccines.fulfilled]: vaccinesAdapter.setAll,
  },
});

export const {
  setVaccineSearchText,
  openNewVaccineDialog,
  closeNewVaccineDialog,
  openEditVaccineDialog,
  closeEditVaccineDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = vaccinesSlice.actions;

export default vaccinesSlice.reducer;
