import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import LoadingModal from "components/custom/LoadingModal";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnimal,
  closeEditAnimalDialog,
  closeNewAnimalDialog,
  updateAnimal,
} from "store/main/animalsSlice";
import { selectowners } from "store/main/ownersSlice";

// import "components/custom/DialogStyle.css";
const defaultFormState = {
  animalId: "",
  owner: "",
  name: "",
  birth: "",
  gender: "",
  familia: "",
  type: "",
  mom: "",
  dad: "",
  desc: "",
  identity: "",
  weight: 0,
};

const genderOptions = [
  { name: "Dişi", id: "Dişi" },
  { name: "Erkek", id: "Erkek" },
];

const familiaOptions = [
  { name: "Kedi", id: "Kedi" },
  { name: "Köpek", id: "Köpek" },
  { name: "Kuş", id: "Kuş" },
  { name: "Papağan", id: "Papağan" },
];

const vaccineStatusOption = [
  { id: 1, name: 1, text: "Oluşturudu" },
  { id: 2, name: 2, text: "Yapıldı" },
  { id: 3, name: 3, text: "İptal Edildi" },
];

function HayvanlarDialog(props) {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();
  const owners = useSelector(selectowners);
  const animalDialog = useSelector(({ animals }) => animals.animalDialog);
  const [ownerId, setOwnerId] = useState({
    id: 0,
    name: "",
  });
  const [genderId, setGenderId] = useState({
    id: 0,
    name: "",
  });
  const [familiaId, setFamiliaId] = useState({
    id: 0,
    name: "",
  });
  const { form, handleChange, setForm } = useForm(defaultFormState);

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (animalDialog.type === "edit" && animalDialog.data) {
      setForm({ ...animalDialog.data });
      let owner = owners.find((f) => f.owner === animalDialog.data.owner);
      setOwnerId(owner);
      let famlia = familiaOptions.find(
        (f) => f.id === animalDialog.data.familia
      );
      setFamiliaId(famlia);
      let gender = genderOptions.find((f) => f.id === animalDialog.data.gender);
      setGenderId(gender);
    }

    /**
     * Dialog type: 'new'
     */
    if (animalDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...animalDialog.data,
      });
    }
  }, [owners, animalDialog.data, animalDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (animalDialog.props.open) {
      initDialog();
    }
  }, [animalDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return animalDialog.type === "edit"
      ? dispatch(closeEditAnimalDialog()).then(() => {
          setForm(defaultFormState);
        })
      : dispatch(closeNewAnimalDialog()).then(() => {
          setForm(defaultFormState);
        });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setDataLoading(true);
    if (animalDialog.type === "new") {
      let data = {
        ...form,
        owner: ownerId.id,
        familia: familiaId.id,
        gender: genderId.id,
      };
      dispatch(addAnimal(data));
      setDataLoading(false);
      dispatch(closeNewAnimalDialog());
      setForm(defaultFormState);
    } else {
      let data = {
        ...form,
        owner: ownerId.id,
        familia: familiaId.id,
        gender: genderId.id,
      };
      console.log("datatoupdate", data);
      dispatch(updateAnimal(data));
      setDataLoading(false);
      dispatch(closeEditAnimalDialog());
      setForm(defaultFormState);
    }
    // closeComposeDialog();
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && canBeSubmitted()) {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      closeComposeDialog();
    }
  };

  function canBeSubmitted() {
    if (dataLoading) {
      return false;
    }
    return form.name.length > 0;
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...animalDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
      onKeyPress={handleKeyPress}
    >
      <DialogTitle id="company-dialog-title" className="dialog-title">
        Hayvan Bilgisi
      </DialogTitle>
      <DialogContent className="dialog-content">
        {dataLoading && <LoadingModal />}
        <MDBox mt={2}>
          <Autocomplete
            value={ownerId}
            options={owners}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            noOptionsText="Seçenek yok"
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Sahip Seçiniz" />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setOwnerId({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setOwnerId({
                  name: newValue.inputValue,
                });
              } else {
                setOwnerId(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={familiaId}
            options={familiaOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            noOptionsText="Seçenek yok"
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Familia Seçiniz" />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setFamiliaId({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setFamiliaId({
                  name: newValue.inputValue,
                });
              } else {
                setFamiliaId(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={genderId}
            options={genderOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            noOptionsText="Seçenek yok"
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Cinsiyet Seçiniz" />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setGenderId({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setGenderId({
                  name: newValue.inputValue,
                });
              } else {
                setGenderId(newValue);
              }
            }}
          />
        </MDBox>
        <TextField
          // autoFocus
          margin="dense"
          id="type"
          name="type"
          label="Tür"
          type="text"
          fullWidth
          variant="outlined"
          value={form.type}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText="Tür"
        />
        <TextField
          // autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText="Adı"
        />
        <TextField
          // // autoFocus
          margin="dense"
          id="identity"
          name="identity"
          label="Kimlik"
          type="number"
          fullWidth
          variant="outlined"
          value={form.identity}
          onChange={handleChange}
        />
        <TextField
          // autoFocus
          margin="dense"
          id="weight"
          name="weight"
          label="Kilo"
          type="number"
          fullWidth
          variant="outlined"
          value={form.weight}
          onChange={handleChange}
          helperText="Kimlik No"
        />

        <TextField
          // autoFocus
          margin="dense"
          id="birth"
          name="birth"
          //   label="D.Günü"
          type="date"
          fullWidth
          variant="outlined"
          value={form.birth}
          onChange={handleChange}
          helperText="D.Günü"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          İptal
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          // autoFocus
          disabled={!canBeSubmitted()}
        >
          {dataLoading ? "Kaydediliyor..." : "Kaydet"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HayvanlarDialog;
