import { Autocomplete, Card, TextField } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import MDBox from "components/MDBox";
import cuid from "cuid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectanimals } from "store/main/animalsSlice";
import {
  addVaccineCard,
  removeVaccineCard,
  selectvaccineCards,
  updateVaccineCard,
} from "store/main/vaccineCardsSlice";
import { selectvaccines } from "store/main/vaccinesSlice";
import AnimalCard from "./AnimalCard";
import CardFound from "./CardFound";
import NoCardFound from "./NoCardFound";

import _ from "lodash";
const AsiTakvimiData = (props) => {
  const dispatch = useDispatch();
  const [animalId, setAnimalId] = useState({
    id: 0,
    name: "",
    title: "",
  });
  const [vaccineId, setVaccineId] = useState({
    id: 0,
    name: "",
    title: "",
  });
  const [data, setData] = useState([]);
  const vaccines = useSelector(selectvaccineCards);
  const vaccineInfos = useSelector(selectvaccines);
  const animals = useSelector(selectanimals);
  const [selectedAnimalData, setSelectedAnimalData] = useState({});

  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    let vaccinesAll = [...vaccines];

    let vaccineDAta = vaccinesAll
      .filter((f) => f.animalId === animalId.id)
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    setData(vaccineDAta);
    let animalData = [...animals];
    let animal = animalData.find((f) => f.animalId === animalId.id);
    setSelectedAnimalData(animal);
  }, [animalId, setAnimalId, animalId.id, vaccines]);

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const changeStatus = React.useCallback(
    (id) => () => {
      let data = { ...id.row, status: 2 };
      if (data.cardId) {
        dispatch(updateVaccineCard(data));
      }
    },
    []
  );
  const deleteData = React.useCallback(
    (params) => async () => {
      const id = params.row.cardId;
      console.log("params", params);
      console.log("selectedAnimalData", selectedAnimalData);
      let vaccineDAtas = [...vaccines];
      const dataIndex = vaccineDAtas.findIndex((item) => item.cardId === id);
      console.log("ide", id);
      console.log("dataIndex", dataIndex);

      // console.log("neden", dataIndex);
      // let vaccine = vaccineDAtas[dataIndex];
      // console.log("vaccine", vaccine);

      if (id) {
        console.log("id", id);
        await dispatch(removeVaccineCard(id));
      } else {
        let vaccineData = [...data];
        vaccineData.splice(dataIndex, 1);
        console.log("vaccineData", vaccineData);
        // setData(vaccineData);
      }
    },
    []
  );

  const processRowUpdate = (newRow, oldRow) => {
    // repeat alanında bir değişiklik var mı diye kontrol edin
    if (newRow.repeat !== oldRow.repeat) {
      // Eski satırı kopyalayın
      const newCopy = { ...newRow, date: newRow.repeat };
      console.log("neler oldu", newCopy);
      // Data'ya yeni satırı ekleyin (Bu örnekte setData state updater fonksiyonudur)
      setData((prevData) => [...prevData, newCopy]);
    }

    // DataGrid'in güncellemesi için yeni satırı döndürün
    return newRow;
  };

  const updateData = (params, value) => {
    console.log("params,", params);
    console.log(params.id);
    console.log(value);
    let vaccineCardsData = [...data];
    const rowData = vaccines.find((item) => item.id === params.id);
    const rowIndex = vaccineCardsData.findIndex(
      (item) => item.id === params.id
    );
    console.log("rowIndex", rowIndex);
    console.log("rowData", rowData); 
    let selected = vaccineCardsData[rowIndex];
    let row = {
      ...selected,
      date: moment(value).format("YYYY-MM-DD"),
      repeat: null,
    };
    dispatch(updateVaccineCard(row));
    // vaccineCardsData.splice(rowIndex, 1);
    // let dataOfChaged = [...vaccineCardsData, row];
    // let sortedValues = _.sortBy(dataOfChaged, ["date"]);

    // setData(sortedValues); // Sort by date
  };

  const addRepeat = async (params, value) => {
    console.log(params.id);
    console.log(value);
    let vaccineCardsData = [...data];
    console.log(vaccineCardsData);
    const rowData = vaccines.find((item) => item.id === params.id);
    console.log(rowData);
    let row = {
      ...rowData,
      id: cuid(),
      cardId: null,
      date: moment(value).format("YYYY-MM-DD"),
      repeat: null,
    };
    dispatch(addVaccineCard(row));

    // setData((current) => _.sortBy([...current, row], ["date"])); // Sort by date
  };

  const handleSaveAll = async () => {
    console.log("comming", data);
    for (const line of data) {
      if (line.cardId && line.cardId.length > 2) {
        await dispatch(updateVaccineCard(line));
      } else {
        await dispatch(addVaccineCard(line));
      }
    }
  };

  const handleAddVaccine = async (vaccine) => {
    let vaccineValues =
      vaccineInfos &&
      vaccineInfos.length > 0 &&
      vaccineInfos.filter((v) => v.id === vaccine.id)[0];
    console.log("vaccineValıes", vaccineValues);
    console.log("vaccines", vaccine);
    if (vaccineValues) {
      let currentDate = moment();
      let date =
        vaccine.date && moment(vaccine.date).isValid
          ? moment(vaccine.date)
          : currentDate;
      console.log("date of change", date);
      let vaccineDate = moment(date).add(
        parseInt(vaccineValues.period),
        "days"
      );

      let row = {
        animalId: animalId.id,
        vaccineId: vaccine.id,
        vaccine: vaccine.name,
        date: moment(vaccineDate),
        repeat: "",
        status: 1,
        carId: null,
      };

      await dispatch(addVaccineCard(row));
      // setData((current) => _.sortBy([...current, row], ["date"])); // Sort by date
    }
  };
  const handleOpenNew = () => {};

  const handleCreateCard = async (form) => {
    let currentDate = form.date.length > 0 ? moment(form.date) : moment();
    let date = currentDate;
    let animal = animals.find((f) => f.animalId === animalId.id);

    let vaccineInfoData = vaccineInfos.filter(
      (f) => f.familia === animal.familia
    );
    let cards = [];
    console.log("vaccineInfoData", vaccineInfoData);
    for (const v of vaccineInfoData) {
      let vaccineDate = date;
      vaccineDate = moment(vaccineDate).add(parseInt(v.period), "days");
      let row = {
        cardId: "",
        animalId: animalId.id,
        vaccineId: v.vaccineId,
        vaccine: v.vaccine,
        date: moment(vaccineDate),
        repeat: "",
        status: 1,
      };
      cards.push(row);
      // setData(cards);
      await dispatch(addVaccineCard(row));
    }
  };

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <Autocomplete
          value={animalId}
          options={animals}
          getOptionLabel={(option) => option.title}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          noOptionsText="Seçenek yok"
          // fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Hayvan Seçiniz" />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue.name === "string") {
              setAnimalId({
                id: newValue.id,
                name: newValue.name,
                title: newValue.title,
              });
            } else if (newValue && newValue.inputValue) {
              setAnimalId({
                name: newValue.inputValue,
                title: newValue.title,
              });
            } else {
              setAnimalId(newValue);
            }
          }}
        />
      </MDBox>
      {selectedAnimalData && selectedAnimalData.id && (
        <MDBox p={3} lineHeight={1}>
          <AnimalCard selectedAnimalData={selectedAnimalData} />
        </MDBox>
      )}
      {data && data.length > 0 ? (
        <CardFound
          key={cuid()}
          data={data}
          pageSize={pageSize}
          setPageSize={setPageSize}
          getBackgroundColor={getBackgroundColor}
          changeStatus={changeStatus}
          deleteData={deleteData}
          handleAddNewVaccine={handleOpenNew}
          processRowUpdate={processRowUpdate}
          addRepeat={addRepeat}
          handleSaveAll={handleSaveAll}
          updateData={updateData}
          handleAddVaccine={handleAddVaccine}
        />
      ) : (
        <NoCardFound handleCreateCard={handleCreateCard} />
      )}
    </Card>
  );
};

AsiTakvimiData.propTypes = {};

export default AsiTakvimiData;
