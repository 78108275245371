import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import LoadingModal from "components/custom/LoadingModal";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addOwner,
  closeEditOwnerDialog,
  closeNewOwnerDialog,
  selectowners,
  updateOwner,
} from "store/main/ownersSlice";

// import "components/custom/DialogStyle.css";
const defaultFormState = {
  owner: "",
  name: "",
  phone: "",
  address: "",
  identity: "",
  status: 1,
};

const statusOptions = [
  { id: "aktif", name: "aktif" },
  { id: "pasif", name: "pasif" },
];

function MusterilerDialog(props) {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();
  const owners = useSelector(selectowners);
  const ownerDialog = useSelector(({ owners }) => owners.ownerDialog);

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "aktif",
    name: "aktif",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (ownerDialog.type === "edit" && ownerDialog.data) {
      setForm({ ...ownerDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (ownerDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...ownerDialog.data,
      });
    }
  }, [owners, ownerDialog.data, ownerDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (ownerDialog.props.open) {
      initDialog();
    }
  }, [ownerDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return ownerDialog.type === "edit"
      ? dispatch(closeEditOwnerDialog()).then(() => {
          setForm(defaultFormState);
        })
      : dispatch(closeNewOwnerDialog()).then(() => {
          setForm(defaultFormState);
        });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setDataLoading(true);
    if (ownerDialog.type === "new") {
      let data = {
        ...form,
      };
      dispatch(addOwner(data)).then(() => {
        setDataLoading(false);
        setForm(defaultFormState);
        dispatch(closeNewOwnerDialog());
      });
    } else {
      let data = {
        ...form,
      };
      console.log("data", data);
      dispatch(updateOwner(data)).then(() => {
        setDataLoading(false);
        dispatch(closeEditOwnerDialog());
        setForm(defaultFormState);
        setStatusValue({
          id: "aktif",
          name: "aktif",
        });
      });
    }
    // closeComposeDialog();
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && canBeSubmitted()) {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      closeComposeDialog();
    }
  };

  function canBeSubmitted() {
    if (dataLoading) {
      return false;
    }
    return form.name.length > 0;
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...ownerDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
      onKeyPress={handleKeyPress}
    >
      <DialogTitle id="company-dialog-title" className="dialog-title">
        Sahip Bilgisi
      </DialogTitle>
      <DialogContent className="dialog-content">
        {dataLoading && <LoadingModal />}

        <TextField
          // autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Ad Soyad"
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.regionName ? false : true}
          helperText="Ad Soyad"
        />
        <TextField
          // // autoFocus
          margin="dense"
          id="phone"
          name="phone"
          label="Telefon"
          type="number"
          fullWidth
          variant="outlined"
          value={form.phone}
          onChange={handleChange}
        />
        <TextField
          // autoFocus
          margin="dense"
          id="identity"
          name="identity"
          label="Kimlik No"
          type="text"
          fullWidth
          variant="outlined"
          value={form.identity}
          onChange={handleChange}
          helperText="Kimlik No"
        />

        <TextField
          // autoFocus
          margin="dense"
          id="address"
          name="address"
          label="Adres"
          type="text"
          fullWidth
          variant="outlined"
          value={form.address}
          onChange={handleChange}
          helperText="Adres"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          İptal
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          // autoFocus
          disabled={!canBeSubmitted()}
        >
          {dataLoading ? "Kaydediliyor..." : "Kaydet"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MusterilerDialog;
