import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  current
} from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import axiosConfig from "../../features/api/axiosConfig";

export const getAnimals = createAsyncThunk(
  "animals/getAnimals",
  async () => {
    const response = await axiosConfig.get(`/api/animals`);
    // const response = await axios.get(`${proxy}/api/animals`)

    let { data } = await response.data;
    return data;
  }
);export const getOwnerAnimals = createAsyncThunk(
  "animals/getOwnerAnimals",
  async (owner, { dispatch, getState }) => {
    // const response = await axios.get(`${proxy}/api/animals`)

    let owners =  current(getState().owners);
    let animals = owners.filter(f=> f.ownerId === owner);
    return animals;
  }
);
export const addAnimal = createAsyncThunk(
  "animals/addAnimal",
  async (animal, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/animals`, animal);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().animals);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().animals);
    }
  }
);

export const updateAnimal = createAsyncThunk(
  "animals/updateAnimal",
  async (animal, { dispatch, getState }) => {
    try {
      
    const response = await axiosConfig.put(
      `/api/animals/${animal.animalId}`,
      animal
    );
    const { data } = await response.data;

    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");

      return animal;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");

      return current(getState().animals);
    }
  } catch (error) {
    console.log("error", error);
  }
  }
);

export const removeAnimal = createAsyncThunk(
  "animals/removeAnimal",
  async (animalId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/animals/${animalId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return animalId;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");

      return current(getState().animals);
    }
  }
);

const animalsAdapter = createEntityAdapter({
  selectId: (animal) => animal.animalId,
});

export const { selectAll: selectanimals, selectById: selectanimalsById } =
  animalsAdapter.getSelectors((state) => state.animals);

const animalsSlice = createSlice({
  name: "animals",
  initialState: animalsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    animalDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setAnimalSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewAnimalDialog: (state, action) => {
      state.animalDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewAnimalDialog: (state, action) => {
      state.animalDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditAnimalDialog: (state, action) => {
      state.animalDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditAnimalDialog: (state, action) => {
      state.animalDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateAnimal.fulfilled]: animalsAdapter.upsertOne,
    [addAnimal.fulfilled]: animalsAdapter.addOne,
    [removeAnimal.fulfilled]: (state, action) =>
      animalsAdapter.removeOne(state, action.payload),
    [getAnimals.fulfilled]: animalsAdapter.setAll,
    [getOwnerAnimals.fulfilled]: animalsAdapter.setAll,
  },
});

export const {
  setAnimalSearchText,
  openNewAnimalDialog,
  closeNewAnimalDialog,
  openEditAnimalDialog,
  closeEditAnimalDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = animalsSlice.actions;

export default animalsSlice.reducer;
