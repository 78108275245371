import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,current
} from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import axiosConfig from "../../features/api/axiosConfig";

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  const response = await axiosConfig.get(`/api/users`);
  // const response = await axios.get(`${proxy}/api/users`)

  let { data } = await response.data;
  return data;
});

export const getUsersByDistrictUser = createAsyncThunk(
  "users/getUsersByDistrictUser",
  async (userName) => {
    const response = await axiosConfig.get(`/api/users/by-user/${userName}`);
    // const response = await axios.get(`${proxy}/api/users`)

    let { data } = await response.data;
    return data;
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async (user, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/users`, user);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().users);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().users);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (user, { dispatch, getState }) => {
    const response = await axiosConfig.put(`/api/users/${user.userName}`, user);
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().users);
    } 
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (user, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/auth/resetpasswordadmin`,
      user
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().users);
    } 
  }
);

export const removeUser = createAsyncThunk(
  "users/removeUser",
  async (userName, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/users/${userName}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return userName;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().users);
    } 
  }
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.userName,
});

export const { selectAll: selectusers, selectById: selectusersById } =
  usersAdapter.getSelectors((state) => state.users);

const usersSlice = createSlice({
  name: "users",
  initialState: usersAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    userDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    passwordDialog: {
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setUserSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openResetPasswordUserDialog: (state, action) => {
      state.passwordDialog = {
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeResetPasswordUserDialog: (state, action) => {
      state.passwordDialog = {
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateUser.fulfilled]: usersAdapter.upsertOne,
    [resetPassword.fulfilled]: usersAdapter.upsertOne,
    [addUser.fulfilled]: usersAdapter.addOne,
    [removeUser.fulfilled]: (state, action) =>
      usersAdapter.removeOne(state, action.payload),
    [getUsers.fulfilled]: usersAdapter.setAll,
    [getUsersByDistrictUser.fulfilled]: usersAdapter.setAll,
  },
});

export const {
  setUserSearchText,
  openNewUserDialog,
  closeNewUserDialog,
  openEditUserDialog,
  closeEditUserDialog,
  openConfirmDialog,
  closeConfirmDialog,
  closeResetPasswordDialog,
  openResetPasswordUserDialog,
  closeResetPasswordUserDialog,
} = usersSlice.actions;

export default usersSlice.reducer;
