import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const CardList = ({ animals, customers, data }) => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Aşı</TableCell>
              <TableCell>Tür</TableCell>
              <TableCell>Hayvan</TableCell>
              <TableCell>Sahibi</TableCell>
              <TableCell>Tarih</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((d) => {
                const animal = animals?.find((a) => a.id === d.animalId);
                const customer = customers?.find((c) => c.id === animal?.owner);
                const rowStatusColor = d.status === 2 ? "row-color-complete" : "";
                return (
                  <TableRow
                    key={uuidv4()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...(rowStatusColor && { bgcolor: rowStatusColor }) }}
                  >
                    <TableCell component="th" scope="row">
                      {d?.vaccineName}
                    </TableCell>
                    <TableCell>{animal?.familia}</TableCell>
                    <TableCell>{animal?.name}</TableCell>
                    <TableCell>{customer?.name}</TableCell>
                    <TableCell>{d?.date}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CardList;
