import { Card, Grid, Icon, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useForm } from "features/hooks";
import moment from "moment";
import React from "react";

const defaultFormState = {
  date: moment().format("YYYY-MM-DD"),
};

const NoCardFound = ({ handleCreateCard }) => {
  const { form, handleChange, setForm } = useForm(defaultFormState);
  const handleCreate = () => {
    handleCreateCard(form);
  };
  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <Typography>Henüz Aşı Takvimi Girilmemiş</Typography>
      </MDBox>

      <Grid container alignItems="center">
        <Grid container spacing={3} alignItems="right">
          <Grid item xs={10} md={6} lg={2} mb={4}>
            <TextField
              // autoFocus
              margin="dense"
              id="date"
              name="date"
              //   label="D.Günü"
              type="date"
              fullWidth
              variant="outlined"
              value={form.date}
              onChange={handleChange}
              helperText="D.Günü"
            />
          </Grid>
          <Grid item xs={10} md={6} lg={2} mb={4}>
            <MDButton variant="gradient" color="info" onClick={handleCreate}>
              <Icon>add</Icon>&nbsp;Aşı Takvimi Oluştur
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

NoCardFound.propTypes = {};

export default NoCardFound;
