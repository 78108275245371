import AsiTakvimiData from "./AsiTakvimiData";
import CalendarData from "./CalendarData";

import AdminLayout from "pages/main/layout/AdminLayout";
const AsiTakvimiDashboard = (props) => {
  return (
    <>
      <AdminLayout>
        {/* <Calendarfilter /> */}
        <AsiTakvimiData />
      </AdminLayout>
    </>
  );
};

AsiTakvimiDashboard.propTypes = {};

export default AsiTakvimiDashboard;
