import { Grid } from "@mui/material";
import AdminLayout from "pages/main/layout/AdminLayout";
import MusterilerData from "./MusterilerData";
import MusterilerDialog from "./MusterilerDialog";
import MusteriHayvanlari from "./MusteriHayvanlari";
import MusteriHayvanlariDialog from "./MusteriHayvanlariDialog";
import ConfirmDialog from "components/custom/ConfirmDiaolig";

const MusterilerDashboard = (props) => {
  return (
    <AdminLayout>
      <MusterilerData />
      <MusteriHayvanlariDialog />
      {/* <Grid container spacing={2} alignItems="center">
        <Grid item lg={6}>
          <MusterilerData />
        </Grid>
        <Grid item lg={6}>
          <MusteriHayvanlari />
        </Grid>
      </Grid> */}
      <MusterilerDialog />
      <ConfirmDialog />
    </AdminLayout>
  );
};

MusterilerDashboard.propTypes = {};

export default MusterilerDashboard;
