import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridActionsCellItem, trTR } from "@mui/x-data-grid";
import theme from "assets/theme";
import CustomGridToolbar from "components/custom/CustomGridToolbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectvaccines } from "store/main/vaccinesSlice";

const CardFound = ({
  data,
  setData,
  pageSize,
  setPageSize,
  getBackgroundColor,
  changeStatus,
  deleteData,
  processRowUpdate,
  addRepeat,
  handleSaveAll,updateData,handleAddVaccine
}) => {
  const dispatch = useDispatch();

  const vaccineInfos = useSelector(selectvaccines);

  const [vaccineId, setVaccineId] = useState({
    id: 0,
    name: "",
  });

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        width: 200,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <CheckCircleIcon style={{ color: theme.palette.success.main }} />
            } // Use theme color for error
            label="Aşı Yapıldı"
            onClick={changeStatus(params)}
            disabled={params.row.status === 2 ? true : false}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: theme.palette.error.main }} />} // Use theme color for error
            label={"Sil"}
            onClick={deleteData(params)}
          />,
        ],
      },
      // {
      //   headerName: "ID",
      //   field: "owner",
      //   minWidth: 70,
      //   hideable: false,
      // },
      {
        headerName: "Aşı",
        field: "vaccine",
        minWidth: 200,
        hideable: false,
      },
      {
        headerName: "Tarih",
        editable: true, // Hücreyi düzenlenebilir yapın
        field: "date",
        minWidth: 180,
        hideable: false,
        type: "date",
        renderCell: (params) => {
          return params.value
            ? moment(params.value).format("DD-MM-YYYY")
            : null;
        },

        renderEditCell: (params) => {
          return (
            <TextField
              type="date"
              defaultValue={moment(params.value).format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                updateData(params.row, event.target.value);
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: moment(event.target.value).format("YYYY-MM-DD"),
                });
              }}
            />
          );
        },
      },
      {
        headerName: "Tekrar",
        field: "repeat",
        minWidth: 180,
        editable: true, // Hücreyi düzenlenebilir yapın
        hideable: false,
        type: "date",
        renderCell: (params) => {
          return params.value
            ? moment(params.value).format("DD-MM-YYYY")
            : null;
        },
        renderEditCell: (params) => {
          return (
            <TextField
              type="date"
              defaultValue={moment(params.value).format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                addRepeat(params, event.target.value);
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: moment(event.target.value).format("YYYY-MM-DD"),
                });
              }}
            />
          );
        },
      },
    ],
    [changeStatus, deleteData]
  );

  const CustomFooter = () => {
    return (
      <MDBox p={2} display="flex" justifyContent="flex-end">
        <MDButton variant="contained" color="primary" onClick={handleSaveAll}>
          Değişiklikleri Kaydet
        </MDButton>
      </MDBox>
    );
  };

  const handleAddNewVaccine = () =>{
    handleAddVaccine(vaccineId)
  }
  return (
    <>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid container spacing={3} alignItems="right">
                <Grid item xs={10} md={6} lg={2} mb={4}>
                  <Autocomplete
                    value={vaccineId}
                    options={vaccineInfos}
                    getOptionLabel={(option) => option.name}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    noOptionsText="Seçenek yok"
                    // fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Aşı Seçiniz" />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue.name === "string") {
                        setVaccineId({
                          id: newValue.id,
                          name: newValue.name,
                        });
                      } else if (newValue && newValue.inputValue) {
                        setVaccineId({
                          name: newValue.inputValue,
                        });
                      } else {
                        setVaccineId(newValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={10} md={6} lg={2} mb={4}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleAddNewVaccine}
                  >
                    <Icon>add</Icon>&nbsp;Yeni Aşı ekle
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ height: 800, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: 400,
                      width: 1,
                      "& .super-app-theme--1": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--2": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.success.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        trTR.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[20, 50, 100]}
                      pagination
                      autoHeight
                      // processRowUpdate={processRowUpdate}
                      components={{
                        Toolbar: CustomGridToolbar,
                        // Footer: CustomFooter,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      // localeText={tableLocalization}
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div style={{ height: 400, width: "100%" }}></div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </>
  );
};

CardFound.propTypes = {};

export default CardFound;
