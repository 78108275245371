import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    current
} from "@reduxjs/toolkit";

export const getOwnerAnimals = createAsyncThunk(
  "ownerAnimals/getOwnerAnimals",
  async (owner, { dispatch, getState }) => {
    // const response = await axios.get(`${proxy}/api/ownerAnimals`)
    try {
      console.log("geldin" );
    let animals = current(getState().animals);
    console.log("getOwnerAnimals owners", animals)
    let ownerAnimals = animals.filter((f) => f.owner === owner);
    console.log("ownerAnimals owners", ownerAnimals)
    return ownerAnimals;
    } catch (error) {
      console.log("error", error)
    }
  }
);

const ownerAnimalsAdapter = createEntityAdapter({
  selectId: (ownerAnimal) => ownerAnimal.animalId,
});

export const {
  selectAll: selectownerAnimals,
  selectById: selectownerAnimalsById,
} = ownerAnimalsAdapter.getSelectors((state) => state.ownerAnimals);

const ownerAnimalsSlice = createSlice({
  name: "ownerAnimals",
  initialState: ownerAnimalsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    ownerAnimalDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setOwnerAnimalSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewOwnerAnimalDialog: (state, action) => {
      state.ownerAnimalDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewOwnerAnimalDialog: (state, action) => {
      state.ownerAnimalDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditOwnerAnimalDialog: (state, action) => {
      state.ownerAnimalDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditOwnerAnimalDialog: (state, action) => {
      state.ownerAnimalDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [getOwnerAnimals.fulfilled]: ownerAnimalsAdapter.setAll,
  },
}); 

export const {
  setOwnerAnimalSearchText,
  openNewOwnerAnimalDialog,
  closeNewOwnerAnimalDialog,
  openEditOwnerAnimalDialog,
  closeEditOwnerAnimalDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = ownerAnimalsSlice.actions;

export default ownerAnimalsSlice.reducer;
