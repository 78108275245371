import { useDispatch, useSelector } from "react-redux";

import { useMemo } from "react";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import cuid from "cuid";
import Calendar from "examples/Calendar";
import moment from "moment";
import {
  openEditVaccineCardDialog,
  selectvaccineCards,
} from "store/main/vaccineCardsSlice";
const CalendarData = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectvaccineCards);

  const selectedYear = moment().format("YYYY");
  const handleEventClick = (clickInfo) => {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }
    let { id } = clickInfo.event;

    let dataToEdit = data.find((f) => f.id == id);
    dispatch(openEditVaccineCardDialog(dataToEdit));
  };
  return (
    <div>
      <MDBox p={3} lineHeight={1}>
        <Grid container spacing={3}>
          <Grid item xs={8} xl={12} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <Calendar
                  key={cuid()}
                  initialView="dayGridMonth"
                  initialDate={
                    selectedYear && selectedYear > 1000
                      ? moment().set("year", selectedYear).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD")
                  }
                  events={data.map((event) => ({
                    ...event,
                    className: event.title && event.title.includes("yapıldı")
                      ? "success"
                      : "error",
                    color:  event.title && event.title.includes("yapıldı") ? "red" : "blue",
                  }))}
                  selectable
                  editable
                  // handleEventClick={handleEventClick}
                />
              ),
              [data, selectedYear]
            )}
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
};

export default CalendarData;
