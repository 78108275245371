import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    current
} from "@reduxjs/toolkit";

export const getAnimalVaccines = createAsyncThunk(
  "animalVaccines/getAnimalVaccines",
  async (owner, { dispatch, getState }) => {
    // const response = await axios.get(`${proxy}/api/animalVaccines`)
    try {
      console.log("geldin" );
    let animals = current(getState().animals);
    console.log("getAnimalVaccines owners", animals)
    let animalVaccines = animals.filter((f) => f.owner === owner);
    console.log("animalVaccines owners", animalVaccines)
    return animalVaccines;
    } catch (error) {
      console.log("error", error)
    }
  }
);

const animalVaccinesAdapter = createEntityAdapter({
  selectId: (animalVaccines) => animalVaccines.animalId,
});

export const {
  selectAll: selectanimalVaccines,
  selectById: selectanimalVaccinesById,
} = animalVaccinesAdapter.getSelectors((state) => state.animalVaccines);

const animalVaccinesSlice = createSlice({
  name: "animalVaccines",
  initialState: animalVaccinesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    animalVaccinesDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setanimalVaccinesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewanimalVaccinesDialog: (state, action) => {
      state.animalVaccinesDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewanimalVaccinesDialog: (state, action) => {
      state.animalVaccinesDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditanimalVaccinesDialog: (state, action) => {
      state.animalVaccinesDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditanimalVaccinesDialog: (state, action) => {
      state.animalVaccinesDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [getAnimalVaccines.fulfilled]: animalVaccinesAdapter.setAll,
  },
}); 

export const {
  setanimalVaccinesSearchText,
  openNewanimalVaccinesDialog,
  closeNewanimalVaccinesDialog,
  openEditanimalVaccinesDialog,
  closeEditanimalVaccinesDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = animalVaccinesSlice.actions;

export default animalVaccinesSlice.reducer;
