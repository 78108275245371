import React from "react";
import PropTypes from "prop-types";
import AdminLayout from "pages/main/layout/AdminLayout";
import HayvanlarAsiDialog from "./HayvanlarAsiDialog";
import HayvanlarData from "./HayvanlarData";
import HayvanlarDialog from "./HayvanlarDialog";

import ConfirmDialog from "components/custom/ConfirmDiaolig";
const HayvanlarDashboard = (props) => {
  return (
    <AdminLayout>
      <HayvanlarData />
      <HayvanlarDialog />
      <HayvanlarAsiDialog />
      <ConfirmDialog />
    </AdminLayout>
  );
};

HayvanlarDashboard.propTypes = {};

export default HayvanlarDashboard;
