import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';

const VaccineCardInfo = ({ todayCount, tomorrowCount, weekCount }) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant="h5" component="h3" gutterBottom>
        Aşı Bilgileri
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <EventNoteIcon />
              <Typography variant="h6" component="h2">
                Bugün
              </Typography>
              <Typography color="textSecondary">Günün Randevu Sayısı</Typography>
              <Typography variant="body2" component="p">
                <strong>{todayCount}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <EventNoteIcon />
              <Typography variant="h6" component="h2">
                Yarın
              </Typography>
              <Typography color="textSecondary">Yarının Randevu Sayısı</Typography>
              <Typography variant="body2" component="p">
                <strong>{tomorrowCount}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <EventNoteIcon />
              <Typography variant="h6" component="h2">
                Bu Hafta
              </Typography>
              <Typography color="textSecondary">Bu Hafta Randevu Sayısı</Typography>
              <Typography variant="body2" component="p">
                <strong>{weekCount}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Diğer kartları da benzer şekilde ekleyebilirsiniz. */}
      </Grid>
    </div>
  );
};

export default VaccineCardInfo;
