import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import axiosConfig from "../../features/api/axiosConfig";

export const getOwners = createAsyncThunk("owners/getOwners", async () => {
  const response = await axiosConfig.get(`/api/owners`);
  // const response = await axios.get(`${proxy}/api/owners`)

  let { data } = await response.data;
  return data;
});

export const addOwner = createAsyncThunk(
  "owners/addOwner",
  async (owner, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/owners`, owner);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().owners);
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştur. Tekrar deneyiniz.");

      return current(getState().owners);
    }
  }
);

export const updateOwner = createAsyncThunk(
  "owners/updateOwner",
  async (owner, { dispatch, getState }) => {
    try {
      console.log("owner.owner",owner.owner)
      const response = await axiosConfig.put(
        `/api/owners/${owner.owner}`,
        owner
      );
      const { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Güncellendi");
        return data;
      } else {
        toastr.error("Hata", "Bir Hata oluştu");
        return current(getState().owners);
      }
    } catch (error) {
      console.log("eerror", error);
    }
  }
);

export const removeOwner = createAsyncThunk(
  "owners/removeOwner",
  async (owner, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/owners/${owner}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return owner;
    } else {
      toastr.error("Hata", "Bir Hata oluştu");
      return current(getState().owners);
    }
  }
);

const ownersAdapter = createEntityAdapter({
  selectId: (owner) => owner.owner,
});

export const { selectAll: selectowners, selectById: selectownersById } =
  ownersAdapter.getSelectors((state) => state.owners);

const ownersSlice = createSlice({
  name: "owners",
  initialState: ownersAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    ownerDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setOwnerSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewOwnerDialog: (state, action) => {
      state.ownerDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewOwnerDialog: (state, action) => {
      state.ownerDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditOwnerDialog: (state, action) => {
      state.ownerDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditOwnerDialog: (state, action) => {
      state.ownerDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateOwner.fulfilled]: ownersAdapter.upsertOne,
    [addOwner.fulfilled]: ownersAdapter.addOne,
    [removeOwner.fulfilled]: (state, action) =>
      ownersAdapter.removeOne(state, action.payload),
    [getOwners.fulfilled]: ownersAdapter.setAll,
  },
});

export const {
  setOwnerSearchText,
  openNewOwnerDialog,
  closeNewOwnerDialog,
  openEditOwnerDialog,
  closeEditOwnerDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = ownersSlice.actions;

export default ownersSlice.reducer;
