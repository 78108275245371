import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import axiosConfig from "features/api/axiosConfig";
import setAuthToken from "features/api/setAuthToken";

export const loadUser = createAsyncThunk("auth/loadUser", async () => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axiosConfig.post(`api/auth/access-token-panel`, {
      token: localStorage.token,
    });
    const data = response.data;
    console.log("success", response.data.success);
    if (response.data.success) {
      if (data) {
        return data;
      }
    } else {
      toastr.error(
        "Üyelik Bitti",
        "Lütfen satış danışmanınızla iletişime geçiniz"
      );
    }
  } catch (error) {
    toastr.error("Oturum Sonlandı", "Tekrar giriş yapınız");
  }
});

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/auth/login`, credentials);
      const data = response.data;

      if (response.data.success) {
        if (data) {
          localStorage.setItem("token", data.access_token);
          return data;
        }
      } else {
        toastr.error("Üyelik Bitti", "Tekrar giriş yapınız");
      }
    } catch (error) {
      toastr.error("Hata", "Bilgiler hatalı");
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/auth/register`, credentials);
      const data = response.data;

      if (response.data.success) {
        if (data) {
          localStorage.setItem("token", data.access_token);
          return data;
        }
      } else {
        toastr.error("Üyelik Bitti", "Tekrar giriş yapınız");
      }
    } catch (error) {
      toastr.error("Hata", "Bilgiler hatalı");
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    localStorage.removeItem("token");
    return {
      token: null,
      isAuthenticated: false,
      loading: false,
    };
  } catch (error) {}
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    loading: true,
  },
  reducers: {},
  extraReducers: {
    [loadUser.fulfilled]: (state, action) => action.payload,
    [logout.fulfilled]: (state, action) => action.payload,
    [login.fulfilled]: (state, action) => action.payload,
    [register.fulfilled]: (state, action) => action.payload,
  },
});

export default authSlice.reducer;
