import cuid from "cuid";
import moment from "moment";
import AdminLayout from "pages/main/layout/AdminLayout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectanimals } from "store/main/animalsSlice";
import { selectowners } from "store/main/ownersSlice";
import { selectvaccineCards } from "store/main/vaccineCardsSlice";
import { selectvaccines } from "store/main/vaccinesSlice";
import BasicInfo from "./BasicInfo";
import CardList from "./CardList";
import VaccineCardInfo from "./VaccineCardInfo";

const Home = (props) => {
  const todayDate = moment().format("YYYY-MM-DD");
  const tommorrowDate = moment().add(1, "days").format("YYYY-MM-DD");
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
  const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
  const endOffWeek = moment().endOf("week").format("YYYY-MM-DD");

  const animals = useSelector(selectanimals);
  const customers = useSelector(selectowners);
  const vaccines = useSelector(selectvaccines);
  const vaccineCards = useSelector(selectvaccineCards);

  const [today, setToday] = useState([]);
  const [tomorrow, setTomorrow] = useState([]);
  const [week, setWeek] = useState([]);
  const [month, setMonth] = useState([]);

  useEffect(() => {
    const todayFiltered = vaccineCards.filter((f) => f.date === todayDate);
    const tomorrowFiltered = vaccineCards.filter(
      (f) => f.date === tommorrowDate
    );
    const weekFiltered = vaccineCards.filter(
      (f) => f.date >= startOfWeek && f.date <= endOffWeek
    );
    const montrhFiltered = vaccineCards.filter(
      (f) => f.date >= startOfMonth && f.date <= endOfMonth
    );

    setToday(todayFiltered);
    setTomorrow(tomorrowFiltered);
    setWeek(weekFiltered);
    setMonth(montrhFiltered);
  }, [vaccineCards]);

  return (
    <AdminLayout>
      <BasicInfo
        animalCount={animals.length}
        customerCount={customers.length}
        vaccineCount={vaccines.length}
      />
      <VaccineCardInfo
        todayCount={today.length}
        tomorrowCount={tomorrow.length}
        weekCount={week.length}
        monthCount={month.length}
      />
      <div className="flex-box-2">
        {today && today.length > 0 && (
          <div className="card-container">
            <h4 className="section-title">Bugün Aşı Listesi</h4>
            <CardList
              key={cuid()}
              data={today}
              animals={animals}
              customers={customers}
            />
          </div>
        )}
        {tomorrow && tomorrow.length > 0 && (
          <div className="card-container">
            <h4 className="section-title">Yarın Aşı Listesi</h4>
            <CardList
              key={cuid()}
              data={tomorrow}
              animals={animals}
              customers={customers}
            />
          </div>
        )}
        {week && week.length > 0 && (
          <div className="card-container">
            <h4 className="section-title">Bu Hafta Aşı Listesi</h4>
            <CardList
              key={cuid()}
              data={week}
              animals={animals}
              customers={customers}
            />
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default Home;
